.project-card-container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.project-card {
  /* outline: red solid 1px; */
  padding: 5px;
}

@media (max-width: 1050px) {
  .project-card-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 820px) {
  .project-card-container {
    display: grid;
    grid-template-columns: 100%;
  }
}
