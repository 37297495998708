.openDisplay {
  background-color: blue;
}
/* .openDisplay:hover {
  background-color: green;
} */

.panel-error {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Chivo;
  font-size: 16px;
}
