#logo {
  /* fill: blueviolet; */
  /* width: 100;
  height: 100; */
  fill: white;
}

#background {
  /* width: 300;
  height: 300; */
}

#box {
  fill: blue;
}
