.modal-root {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.65); /* Black w/ opacity */
  /* backdrop-filter: blur(2px); */
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 220px;
  justify-content: center;
  align-items: center;
  /* width: 500px; */
  /* background: goldenrod; */
  /* display: flex; */
  /* height: 100px; */
  /* min-height: 500px; */
  /* flex-wrap: wrap; */
}

.form-inputs {
  width: 50%;
  max-width: 600px;
  /* height: 50%; */
  outline: red solid 1px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  border-radius: 8px;
}

.form-input {
  /* outline: green solid 1px; */
  /* min-width: 200px; */
  width: 45%;
  min-width: 250px;
  flex: 1;
  margin-inline: 10px;
  /* width: 250px; */
}

.button-container {
  width: 45%;
  min-width: 250px;
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-inline: 10px;
  justify-content: space-between;
  align-items: center;
  /* background: green; */
}

.delete-button {
  /* width: 30vw;
  height: 30vw; */
  height: 70%;
  max-width: 200px;
  min-width: 70px;
  max-height: 50px;
  border-radius: 10px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  transition: background 70ms, color 0ms;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.479);
  font-family: Chivo;
  font-weight: 300;
  /* font-size: 14px; */
  font-family: Chivo;
  padding: 5px;
  padding-inline: 14px;
  user-select: none;
}

.delete-button:hover {
  background: white;
  color: black;
  box-shadow: 1px 2px 7px black;
  cursor: pointer;
  font-weight: 400;
}

.cancel {
  background: white;
  color: black;
}

.delete {
  background: white;
  color: black;
  outline: solid orange 1px;
}
