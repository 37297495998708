.svg-container {
  height: 1000px;
  margin-top: 53px;
}

#tooltip {
  width: 200px;
  height: 200px;
  background: black;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  /* transform: translate(0px, -150px); */
  /* transition: transform 2s liner; */
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation: tooltipAnimation 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  /* transition: opacity 100ms; */
}

.triangle {
  display: inline-block;
  width: 30%;
  height: 30%;
  transform-origin: center;
  transform: rotate(45deg) translate(100%, 100%);
  /* transform: translateY(50%); */
  background: black;
  /* outline: red solid; */
}

.tooltip-text {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10%;
  /* font-size: clamp(10px, 10%, 39px); */
  font-size: max(70%, 15px);
  color: orange;
  font-family: Chivo;
  display: flex;
  justify-content: center;
  align-items: center;
  /* outline: red solid; */
}

@media (max-width: 1100px) {
  .svg-container {
    height: 90vw;
  }
}

@media (max-width: 475px) {
  .svg-container {
    height: 500px;
    padding-left: 175px;
  }

  .tooltip-text {
    font-size: 12px;
  }
}

@keyframes tooltipAnimation {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, -5px);
  }
}

#PhoneGrid rect {
  cursor: pointer;
  border-style: solid;
  border-width: 1;
}

#PhoneGrid text {
  user-select: none;
  cursor: pointer;
}

.st0 {
  fill: none;
}
.st1 {
  fill: none;
  stroke: #999999;
  stroke-width: 0.75;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st2 {
  fill: none;
  stroke: #999999;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st3 {
  display: none;
}
.st4 {
  display: inline;
  fill: none;
  stroke: #999999;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st5 {
  display: inline;
  fill: none;
  stroke: #999999;
  stroke-width: 12;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st6 {
  display: inline;
  fill: none;
}
.st7 {
  display: inline;
  fill: none;
  stroke: #999999;
  stroke-width: 13;
  stroke-miterlimit: 10;
}
.st8 {
  display: inline;
  fill: none;
  stroke: #1d1d00;
  stroke-width: 12;
  stroke-miterlimit: 10;
}
.st9 {
  display: inline;
}
.st10 {
  fill: none;
  stroke: #999999;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #fff;
  stroke: #999999;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st12 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 0.65;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st13 {
  fill: none;
  stroke: #999999;
  stroke-width: 0.65;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st14 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 1.75;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 0.6281;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st17 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st18 {
  fill: none;
  stroke: #9f9f9f;
  stroke-width: 1.25;
  stroke-miterlimit: 10;
}
.st19 {
  fill: none;
  stroke: #a0a0a0;
  stroke-width: 1.25;
  stroke-miterlimit: 10;
}
.st20 {
  fill: none;
  stroke: #9f9f9f;
  stroke-width: 1.4622;
  stroke-miterlimit: 10;
}
.st21 {
  fill: none;
  stroke: #b8b8b8;
  stroke-width: 1.4622;
  stroke-miterlimit: 10;
}
.st22 {
  fill: none;
  stroke: #999999;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st23 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st24 {
  fill: none;
  stroke: #999999;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st25 {
  fill: none;
  stroke: #999999;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st26 {
  fill: none;
  stroke: #999999;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st27 {
  display: inline;
  fill: #fff;
  stroke: #999999;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st28 {
  fill: #fff;
}
.st29 {
  fill: #999999;
}
.st30 {
  fill: none;
  stroke: #999999;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}
.st31 {
  fill: none;
  stroke: #999999;
  stroke-width: 1.75;
  stroke-miterlimit: 10;
}
.st32 {
  fill: none;
  stroke: #b8b8b8;
  stroke-width: 1.25;
  stroke-miterlimit: 10;
}
.st33 {
  fill: none;
  stroke: #9f9f9f;
  stroke-miterlimit: 10;
}
.st34 {
  fill: none;
  stroke: #9f9f9f;
  stroke-width: 1.15;
  stroke-miterlimit: 10;
}
.st35 {
  fill: none;
  stroke: #b8b8b8;
  stroke-miterlimit: 10;
}
.st36 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st37 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st38 {
  fill: url(#Rectangle_5_00000170240004795152308700000002812699972897158285_);
}
.st39 {
  fill: none;
  stroke: #999999;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.st40 {
  font-family: "Chivo";
  font-size: 22px;
}
.st41 {
}
.st42 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 1.4873;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st43 {
  fill: #fff;
  stroke: #999999;
  stroke-width: 5.6862;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
