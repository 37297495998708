.root {
  border-radius: 20px;
}

.root-container {
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #faf8ef;
  padding: 30px;
  align-items: center;
  border-style: solid;
  border-width: 4px;
  border-radius: 1px;
  /* padding-top: 300px; */
}

.platform-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.platform-button-container {
  display: flex;
  flex-direction: row;
}

.platform-header {
  font-family: Chivo;
  font-weight: 700;
  font-size: 32px;
}

.platform-button {
  width: 30vw;
  height: 30vw;
  max-width: 175px;
  max-height: 175px;
  border-radius: 10px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  margin: 30px;
  margin-bottom: 20px;
  /* margin-inline: 20px;
  margin-top: 15px; */
  transition: 150ms;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.479);
  font-family: Chivo;
  font-weight: 300;
  font-size: 22px;
}

.platform-button:hover {
  background: white;
  color: black;
  box-shadow: 1px 2px 7px black;
  cursor: pointer;
  font-weight: 400;
}

.form-header {
  font-family: Chivo;
  font-weight: 900;
  font-size: 20px;
  margin-top: -5px;
}

.form-description {
  font-size: 16px;
  max-width: 300px;
  margin-inline: auto;
  /* border-style: groove;  */
  margin-bottom: 30px;
  text-align: center;
  /* padding-inline: auto; */
}

.form-field-title {
  font-family: Chivo;
  font-weight: 700;
  font-size: 20;
}

.application {
  margin-top: 15px;
}

.button {
  width: 200px;
  height: 40px;
  border-radius: 25px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdad0d;
  margin-inline: auto;
  margin-top: 25px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.479);
  transition: box-shadow 150ms;
}

.button:hover {
  background: white;
  color: black;
  box-shadow: 1px 2px 7px black;
  cursor: pointer;
}

.cancel {
  background: white;
  color: black;
}

/* .cancel:hover {
  background: black;
  color: white;
  box-shadow: 1px 2px 7px black;
  cursor: pointer;
} */

.loading-container {
  margin: 175px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  text-align: center;
}

.res-success-container {
  min-width: 250px;
  min-height: 250px;
  padding: 75px;
  text-align: center;
  font-size: 17px;
}

.res-success-button {
}

.progress {
  margin-bottom: 30px;
}
