* {
  /* outline: red solid 1px; */
}

.slogan {
  font-weight: 700;
  font-family: Chivo;
  letter-spacing: -5.1;
  font-size: clamp(64px, 8vw, 120px);
  line-height: max(64px, 8vw);
}

.end {
  text-align: right;
}

.tagline {
  /* background: red; */
  /* outline: red solid 1px; */
  margin-top: 50px;
  font-weight: 700;
  color: "#305105";
  font-family: Chivo;
  font-size: clamp(15px, 5vw, 26px);
}

.tagline-container {
  /* background: blue; */
  display: flex;
  justify-content: center;
}

.button {
  padding: 26px;
  border-radius: 26px;
}

@media (max-width: 600px) {
  .slogan {
    text-align: center;
  }
}
