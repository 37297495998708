.root {
  width: 100vw;
  height: 100vh;
}

.top-toolbar-padding {
  width: 100%;
  height: 48px;
  background: hotpink;
}

.under-toolbar-container {
  width: 100vw;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: row;
  /* background: blue; */
}

.side-toolbar-padding {
  width: 220px;
  height: 100%;
  /* background: goldenrod; */
}

.body-container {
  flex: 1;
  /* position: fixed; */
  width: calc(100% - 220px);
  overflow: hidden;
  height: 100%;
  color: white;
}

.new-and-edit-container {
  display: flex;
  justify-content: space-between;
}

.new-panel-button {
  width: 30vw;
  height: 30vw;
  max-width: 200px;
  max-height: 50px;
  border-radius: 10px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  margin: 30px;
  margin-bottom: 20px;
  /* margin-inline: 20px;
  margin-top: 15px; */
  transition: background 70ms, color 0ms;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.479);
  font-family: Chivo;
  font-weight: 300;
  font-size: 20px;
}

.new-panel-button:hover {
  background: white;
  color: black;
  box-shadow: 1px 2px 7px black;
  cursor: pointer;
  font-weight: 400;
}

.new-panel-button-placeholder {
  width: 30vw;
  height: 30vw;
  width: 200px;
  max-height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  margin: 30px;
  margin-bottom: 20px;
}

.selected-panel-container {
  /* background: green; */
  color: black;
  min-width: 350px;
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: black solid 2px;
  margin-top: 15px;
  border-radius: 8px;
  font-family: Chivo;
  font-size: 16px;
}

.sort {
  /* background: blue; */
  margin: 5px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  font-size: 16px;
}

.filter-container {
  height: 60px;
  /* background: #888888; */
  border-radius: 3px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: black;
  font-family: Chivo;
  font-weight: 500;
  font-size: 16px;
  align-content: center;
  /* max-width: 600px; */
}

.filter-header {
  flex: 1;
  display: flex;
  justify-content: space-between;
  /* background-color: aquamarine; */
  min-width: 0px;
  max-width: 300px;
  padding-inline: 5px;
  /* border: black solid 2px; */
}

.filter-input {
  margin-left: 10px;
  width: 80%;
  height: 30px;
  padding-left: 6px;
}

.filter-make {
  flex: 2;
}

.filter-model {
  flex: 2;
}

.filter-button {
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  border: black solid 1px;
  /* margin-inline: 15px; */
  width: 20px;
  height: 20px;
  border-radius: 10px;
  transition: background 100ms;
  margin-left: 10px;
}

.filter-button:hover {
  background: red;
}

.panel-list-container {
  overflow: scroll;
  height: min-content;
  position: fixed;
  height: calc(100vh - 251px);
  overflow: auto;
  flex: 1;
  width: calc(100vw - 221px);
  /* outline: red solid 1px; */
}

.panel-container-header {
  height: 40px;
  /* background: #888888; */
  border-radius: 3px;
  margin: 5px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.panel-container {
  height: 40px;
  background: rgb(235, 235, 235);
  border-radius: 3px;
  margin: 5px;
  display: flex;
  /* justify-content: center; */

  align-items: center;
}

.panel-container:hover {
  cursor: pointer;
  background: rgb(194, 194, 194);
}

.panel-data {
  /* background: indigo; */
  font-family: Chivo;
  color: #202020;
  margin-inline: 3px;
  padding-inline: 3px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  user-select: none;
}

.make {
  flex: 2;
  justify-content: left;
}
.model {
  justify-content: left;
}
