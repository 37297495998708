.debug {
  /* outline: red solid 1px; */
}

.header-container {
  margin-inline: auto;
  padding-left: 32%;
  padding-right: 10vw;
  margin-top: -350px;
  margin-bottom: 80px;
  display: block;
  max-width: 1500px;
  z-index: 3;
  pointer-events: none;
  cursor: none;
}

.header-new {
  font-family: Chivo;
  font-size: 60px;
  font-weight: 900;
  background: "";
  text-align: right;
  pointer-events: none;
  z-index: 1;
  cursor: none;
}

.header-body {
  font-family: Chivo;
  font-size: 24px;
  font-weight: 300;
  display: inline-block;
  text-align: right;
  pointer-events: none;
}

.but-why-container {
  width: 90%;
  margin-inline: auto;
  margin-top: 40px;
  padding-top: 50px;
  border-radius: 8px;
  box-shadow: 0px 0px 50px #0000006b;
  max-width: 1000px;
  border-bottom-width: 2px;
  /* transition-property: box-shadow; */
  /* transition: 200ms; */
}

/* .but-why-container:hover {
  box-shadow: 0px 0px 15px #000000e0;
} */

.howDoesItWorkContainer {
  /* background: red; */
  width: 90%;
  max-width: 1000px;
  margin-inline: auto;
  padding-top: 60px;
  display: flex;
}

.containerForCards-computer {
  /* position: relative; */
  flex-grow: 1;
  padding-inline: 4px;
  padding-bottom: 4px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 2px 30px rgba(43, 43, 43, 0.404);
}

.containerForCards-app {
  position: relative;
  flex-grow: 1;
  padding-inline: 4px;
  padding-bottom: 4px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px 2px 5px rgb(43, 43, 43);
}

.fixedTab {
  padding-left: 10px;
  position: sticky;
  top: 400px;
  font-size: 26px;
  font-family: Chivo;
  font-weight: 900;
  min-width: 100px;
  height: 300px;
  padding-top: 15px;
  color: rgb(255, 255, 255);
  z-index: 2;
  box-shadow: -1px 2px 5px rgb(43, 43, 43);
  /* margin-right: -10px; */
}

#fixedTab-computer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0px;
}

#fixedTab-app {
  min-width: 120px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 8px;
}

.howDoesItWork-card-body-computer {
  margin-top: 5px;
  color: black;
  border-color: white;
  margin-inline: auto;
  font-family: Chivo;
  border-width: 1px;
  padding-block: 10px;
  display: flex;
  border-radius: 10px;
}

.howDoesItWork-card-body-computer-reverse {
  margin-top: 5px;
  color: black;
  border-color: white;
  margin-inline: auto;
  font-family: Chivo;
  border-width: 1px;
  padding-block: 10px;
  display: flex;
  border-radius: 10px;
  flex-direction: row-reverse;
}

.howDoesItWork-card-words-computer {
  margin-block: auto;
  margin-right: 30px;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.howDoesItWork-card-words-computer-reverse {
  margin-block: auto;
  margin-left: 50px;
  font-size: 18px;
  font-weight: 300;
  text-align: right;
}

@media (max-width: 1100px) {
  .header-container {
    margin-top: -31vw;
    padding-left: 25%;
  }
}

@media (max-width: 700px) {
  .header-container {
    padding-left: 15%;
  }

  .containerForCards-computer {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .containerForCards-app {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  #howDoesItWork-container-computer {
    flex-direction: column;
    justify-content: center;
    /* position: absolute; */
  }

  #howDoesItWork-container-app {
    flex-direction: column-reverse;
    justify-content: center;
  }

  #fixedTab-computer {
    top: 50px;
    width: 100%;
    height: 15%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  #fixedTab-app {
    top: 50px;
    width: 100%;
    height: 15%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .fixedTab {
    text-align: center;
    border-style: solid;
    border-top-color: white;
    border-top-width: 30px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    box-shadow: 0px 0px 0px rgb(43, 43, 43);
  }
}

@media (max-width: 475px) {
  .header-container {
    margin-top: -170px;
    padding-inline: 25px;
    padding-bottom: 0px;
    margin-bottom: 10px;
    text-align: center;
  }

  .header-new {
    font-size: 38px;
  }

  .header-body {
    font-size: 18px;
    padding-left: 0px;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .howDoesItWorkContainer {
    padding-top: 20px;
  }

  .containerForCards-computer {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .containerForCards-app {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .howDoesItWork-card-body-computer {
    flex-direction: column;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .howDoesItWork-card-body-computer-reverse {
    flex-direction: column;
  }

  .howDoesItWork-card-image-computer {
    margin-inline: auto;
    padding-inline: 10;
  }

  .howDoesItWork-card-words-computer {
    margin-block: auto;
    padding-inline: 15px;
    margin-right: 0px;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
  }

  .howDoesItWork-card-words-computer-reverse {
    margin-block: auto;
    padding-inline: 15px;
    margin-right: 0px;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    margin-inline: 0px;
  }
}
